export default [  
  {
    familyID: "7299",
    location: "Boulder, CO",
    startDate: "January 2025",
    schedule: "Monday-Friday 8am-5pm, Possibly some Saturdays",
    hours: "40-48 Hours/Week",
    pay: "$30+/hour",
    placementLength: "3+ months",
    nKids: "Newborn baby",
    familydescription:
      "Family of 3 (including newborn), 1 dog, 1 cat",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Dec 20th, 2024"
  },
  {
    familyID: "2678, 2083",
    location: "Boulder, CO",
    startDate: "February - April 2025",
    schedule: "Monday - Friday 9am-5pm",
    hours: "40 Hours/Week",
    pay: "$32-40/hour",
    placementLength: "Long term, or 1 year commitments",
    nKids: "Two infants, nanny share",
    familydescription:
      "One family of 4, One family of 3 + Dog",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Dec 2nd, 2024"
  },
  {
    familyID: "7279",
    location: "Boulder, CO",
    startDate: "January 2025",
    schedule: "Monday - Friday 4-6PM or 4-7PM",
    hours: "10-15+ Hours",
    pay: "$30+/hour",
    placementLength: "Long term",
    nKids: "1 child, 1.5 years old",
    familydescription:
      "Family of 5",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Nov 29th, 2024"
  },
  {
    familyID: "5892",
    location: "Longmont/Lyons, CO",
    startDate: "ASAP",
    schedule: "Must have availability Monday, 8:30-5:30 Tuesday 2pm-8pm Thursday date night 2pm-9:30 Friday 8:30-5:30 2 weekend days a month, however we will guarantee 22 hours per week within these time frames, typically 22-30 hours per week, international travel with family is a huge plus ",
    hours: "22-30 Hours",
    pay: "$30/hour",
    placementLength: "Long term",
    nKids: "3 children, 8 years, 3.5 years, 2 years",
    familydescription:
      "Family of 5 + dog + cat",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Oct 23rd, 2024"
  },
  {
    familyID: "3291",
    location: "Boulder, CO",
    startDate: "Jan 6th, 2025",
    schedule: "M-F, 8:45am-5:30pm",
    hours: "43.75 Hours",
    pay: "$25-30/hour depending on experience",
    placementLength: "Long term",
    nKids: "1-2 kids, 4 years old & 5 months old",
    familydescription:
      "Family of 4, no pets",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Oct 26th, 2024"
  }
];
